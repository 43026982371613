import { createContext } from "react"
import * as PropTypes from "prop-types"

export const HomeContext = createContext({})

function HomeContextProvider({ children, cars, posts, testimonials, featuredImage, footerLinks, landingsList }) {
  const featuredOffer = cars.length > 0 && cars[0].featuredOffer ? cars[0] : null
  const otherCars = featuredOffer ? cars.slice(1) : cars

  return (
    <HomeContext.Provider value={{ featuredOffer, cars: otherCars, posts, testimonials, featuredImage, footerLinks, landingsList }}>
      {children}
    </HomeContext.Provider>
  )
}

HomeContextProvider.defaultProps = {
  landingsList: [],
  footerLinks: [],
  cars: [],
  featuredImage: null
}

HomeContextProvider.propTypes = {
  cars: PropTypes.array,
  landingsList: PropTypes.array,
  footerLinks: PropTypes.array,
  posts: PropTypes.array.isRequired,
  testimonials: PropTypes.object.isRequired,
  featuredImage: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default HomeContextProvider
