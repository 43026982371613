import { useContext } from "react"
import DefaultLayout from "components/layouts/DefaultLayout"
import dynamic from "next/dynamic"
import LazyLoad from "react-lazyload"
import { FullContainer, WhiteSection } from "components/styled/Grid"
import LoadingPlaceholder from "components/LoadingPlaceholder"
import { HomeContext } from "./HomeContext"

const HomeHeader = dynamic(() => import("./Header"), { loading: () => <LoadingPlaceholder /> })
const Header = dynamic(() => import("scenes/OldEmployeeScene/Header"), { loading: () => <LoadingPlaceholder /> })
const Brands = dynamic(() => import("components/landing/Brands"), { loading: () => <LoadingPlaceholder /> })
const Offers = dynamic(() => import("components/landing/OffersWrapper"), { loading: () => <LoadingPlaceholder /> })
const AboutRenting = dynamic(() => import("components/landing/AboutRenting"), { loading: () => <LoadingPlaceholder /> })
const Kinds = dynamic(() => import("components/Kinds"), { loading: () => <LoadingPlaceholder /> })
const FastDelivery = dynamic(() => import("./FastDeliveryBanner"), { loading: () => <LoadingPlaceholder /> })
const Testimonials = dynamic(() => import("./Testimonials"), { loading: () => <LoadingPlaceholder /> })
const Articles = dynamic(() => import("components/landing/Articles"), { loading: () => <LoadingPlaceholder /> })
const Press = dynamic(() => import("components/Press"), { loading: () => <LoadingPlaceholder /> })

export default function HomeScene() {
  const { featuredOffer, posts, cars, footerLinks } = useContext(HomeContext)

  const header = featuredOffer ? <HomeHeader landing="home" featuredOffer={featuredOffer} /> : <Header landing="home" />

  return (
    <DefaultLayout page="home" grayCta footerLinks={footerLinks}>
      {header}
      <Brands page="home" />
      <WhiteSection>
        <LazyLoad height={500} offset={200} once>
          <Offers cars={cars} page="home" />
        </LazyLoad>
        <LazyLoad height={500} offset={200} once>
          <FullContainer maxWidth={1024}>
            <Kinds page="home" />
            <FastDelivery />
            <AboutRenting landing="home" />
          </FullContainer>
        </LazyLoad>
      </WhiteSection>
      <LazyLoad height={500} offset={200} once>
        <WhiteSection>
          <Testimonials />
          <Articles posts={posts} page="home" />
        </WhiteSection>
      </LazyLoad>
      <LazyLoad height={500} offset={200} once>
        <Press />
      </LazyLoad>
    </DefaultLayout>
  )
}
