import { I18n } from "react-polyglot-hooks"
import * as PropTypes from "prop-types"
import HomeScene from "scenes/HomeScene"
import { getHomeData } from "services/webService"
import { getBlogPosts } from "services/blogService"
import HomeContextProvider from "scenes/HomeScene/HomeContext"
import getFeaturedImage from "helpers/getFeaturedImage"
import common from "../../i18n/es/common"
import car from "../../i18n/es/car"
import home from "../../i18n/es/home"
import landings from "../../i18n/es/landings"
import NotFound from "../404"
import { getFooterLinks, getLandingsList } from "../../src/services/seoService"

function Index({ cars, posts, testimonials, notFound, featuredImage, footerLinks, landingsList }) {
  if (notFound) return <NotFound />

  return (
    <I18n locale="es" phrases={{ ...common, ...car, ...home, ...landings }}>
      <HomeContextProvider cars={cars} posts={posts} testimonials={testimonials} featuredImage={featuredImage} footerLinks={footerLinks} landingsList={landingsList}>
        <HomeScene />
      </HomeContextProvider>
    </I18n>
  )
}

Index.defaultProps = {
  cars: [],
  footerLinks: [],
  landingsList: [],
  posts: [],
  testimonials: {},
  notFound: false,
  featuredImage: null
}

Index.propTypes = {
  cars: PropTypes.array,
  footerLinks: PropTypes.array,
  landingsList: PropTypes.array,
  posts: PropTypes.array,
  testimonials: PropTypes.object,
  notFound: PropTypes.bool,
  featuredImage: PropTypes.string
}

export async function getStaticProps() {
  const resTestimonials = await import("../../i18n/es/home_testimonials")
  const resHome = await getHomeData("es")
  const posts = await getBlogPosts("es", 4922)

  if (resHome.status === 404) return { props: { notFound: true }, revalidate: 300 }

  // Footer Links
  const footerLinksRes = await getFooterLinks()
  const footerLinks = await footerLinksRes.json()

  // Landing List
  const landingListRes = await getLandingsList();
  const landingListdata = await landingListRes.json();
  const landingsList = Object.keys(landingListdata).map((key) => ({
    key,
    value: landingListdata[key],
  }));

  const cars = await resHome.json()
  const testimonials = resTestimonials.default
  const featuredImage = await getFeaturedImage(cars?.[0])

  return {
    props: {
      cars,
      posts,
      testimonials,
      featuredImage,
      footerLinks,
      landingsList
    },
    revalidate: 300
  }
}

export default Index
